//base url
export const LOGIN_PAGE = 'https://dev-home.phillipsoutsourcing.net/login';
export const MAIN_MENU = 'https://dev-home.phillipsoutsourcing.net/home';
export const API_BASE_URL = 'https://dev-api.phillipsoutsourcing.net';
export const DOCUMENT_BASE_URL = 'https://s3.us-east-1.amazonaws.com/dev-api.phillipsoutsourcing.net';

//user
export const GET_USER_AUTHORIZATION = '/get-user-authorization';
export const CHANGE_USER_PASSWORD = '/change-user-password';
export const RESET_USER_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const CONFIRM_PASSWORD = '/user/confirm-password';
export const ENABLE_2FA = '/user/two-factor-authentication';
export const TWO_FACTOR_QR_CODE = '/user/two-factor-qr-code';
export const TWO_FACTOR_RECOVERY_CODES = '/user/two-factor-recovery-codes';
export const TWO_FACTOR_CHALLENGE = '/two-factor-challenge';


//application url
export const ADMIN_URL = 'https://dev-admin.phillipsoutsourcing.net/home';
export const PAYROLL_URL = 'https://dev-payroll.phillipsoutsourcing.net/home';
export const ESM_URL = 'https://dev-esm.phillipsoutsourcing.net/home';
export const ACCOUNT_URL = 'https://dev-accounting.phillipsoutsourcing.net/home';
export const RECRUITMENT_URL = 'https://dev-recruitment.phillipsoutsourcing.net/home';
export const SELF_SERVICE_URL = 'https://dev-selfservice.phillipsoutsourcing.net/home';
export const MANAGEMENTS_URL = 'https://dev-management.phillipsoutsourcing.net/home';
export const CRM_URL = 'https://dev-crm.phillipsoutsourcing.net/home';


//Help Desk

export const SUPPORT_METRICS = '/get-support-metrics';
export const SUPPORT_TICKETS = '/supports';
export const SUPPORT_CATEGORIES = '/get-support-categories';

//fetch

export const FETCH_EMPLOYEE_VALIDATION = '/fetch-employee-validations'
export const APPROVE_EMPLOYEE_VALIDATION = '/approve-employee-validation'
export const REMOVE_EMPLOYEE_VALIDATION = '/disapprove-employee-validation'
export const FINAL_SUBMISSION = '/final-employee-validation'
export const GET_ACTIVE_PROFILE_VERIFICATION = '/get-active-profile-verification'


export const TOGGLE_CHECK_IN = '/toggle-check-in';
export const GET_EMPLOYEE_TIME = '/get-employee-time';


